<template>
  <div>
    <div class="mb-0 overflow-hidden">
      {{ post.title }}
      <span v-if="showProgressBar">: {{ $t('Generating') }} {{ progress }}/{{ total }}</span>
      <b-progress
        v-if="showProgressBar"
        variant="success"
        :value="progress"
        :max="total"
        height="5px"
        striped
        animated
      />
    </div>
  </div>
</template>

<script setup>
import { BProgress } from 'bootstrap-vue'
import { computed, defineProps } from 'vue'
import { getCurrentCampaignProgresses } from '../useSocialCampaign'

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
})

const campaignInProgress = computed(() => getCurrentCampaignProgresses(props.post.extendedProps?.generations ? props.post.extendedProps?.uuid : null))
const progress = computed(() => campaignInProgress.value?.progress)
const total = computed(() => campaignInProgress.value?.total)
const showProgressBar = computed(() => campaignInProgress.value && progress.value !== total.value)

</script>
