<template>
  <b-modal
    id="event-details-modal"
    :title="campaign ? campaign.name : $t('social-campaign.new-campaign')"
    no-close-on-backdrop
    hide-footer
    @hidden="setCurrentCampaign(null)"
  >
    <create-edit-campaign
      @refetch-calendar="campaignUid => $emit('refetch-calendar', campaignUid)"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import CreateEditCampaign from './CreateEditCampaign.vue'
import { getCurrentCampaign, setCurrentCampaign } from '../useSocialCampaign'

export default {
  components: {
    BModal,
    CreateEditCampaign,
  },
  computed: {
    campaign() {
      return getCurrentCampaign()
    },
  },
  methods: {
    setCurrentCampaign,
  },
}
</script>

<style lang="scss">
#event-details-modal {
  .text-gray {
    color: #d0d2d6;
  }
}
</style>
